<template>
	<div class="broker-header">
		<div class="container">
			<div class="broker-header__content">
				<!--				<a-->
				<!--					class="broker-header__logo"-->
				<!--					:to="{ name: 'MainRoute', params: { lang: $store.state.uiLanguage } }"-->
				<!--				>-->
				<!--					<img-->
				<!--						src="@/assets/img/svg/logo.svg"-->
				<!--						width="231"-->
				<!--						height="52"-->
				<!--						alt="Логотип"-->
				<!--					/>-->
				<!--				</a>-->
				<div class="broker-header__logo-text">
					{{ $store.getters.isBroker ? brokerTitle : 'Create agency profile' }}
					<span v-if="offerId">{{ subHeaderText }}</span>
				</div>
				<template v-if="$store.getters.isAuth">
					<div class="broker-header__controls">
						<template v-if="$store.getters.isBroker">
							<or-link
								:to="{
									name: 'BrokerSearch',
									params: { lang: $store.state.uiLanguage }
								}"
								class="broker-header__controls-btn"
								kind="solid-border"
								:height="48"
							>
								Projects
							</or-link>
							<or-link
								kind="solid-border"
								:height="48"
								class="broker-header__controls-btn"
								:to="{
									name: 'CreateOffer',
									params: { lang: $store.state.uiLanguage }
								}"
							>
								{{ unitsCount ? 'Create offer' : 'Offers' }}
								<span v-if="unitsCount"> ({{ unitsCount }}) </span>
							</or-link>
						</template>
						<router-link
							:to="{
								name: 'BrokerProfile',
								params: { lang: $store.state.uiLanguage }
							}"
							:class="{ isBroker: $store.getters.isBroker }"
							class="btn small broker-header__controls-icon-btn"
						>
							<or-icon type="profile" />
						</router-link>
						<Navigation
							v-if="!isCreateOffer && !isBrokerProfile"
							class="broker-header__navigation"
							broker
						/>
						<button
							class="small btn broker-header__controls-icon-btn"
							@click="logout"
						>
							<or-icon type="logout" />
						</button>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import requestConfigs from '@/api/requestBrokerConfigs'
import { createRequestBroker } from '@/api/requestBuilder'
import { defineAsyncComponent } from 'vue'
import OrButton from '@/components/global/orButton.vue'

export default {
	components: {
		OrButton,
		Navigation: defineAsyncComponent(() =>
			import('./components/Navigation.vue')
		)
	},
	data() {
		return {
			activeMenu: false
		}
	},
	computed: {
    offerId() {
      return this.$store.state.broker.offer.id
    },
    subHeaderText() {
      let text = this.$t('web.editing_offer') + ' '
      const offerTitle = this.$store.state.broker.offer.title
      text += offerTitle.length > 20 ? offerTitle.substring(0, 19) + '...' : offerTitle
      return text
    },
		isBrokerProfile() {
			return ['BrokerProfile'].includes(this.$route.name)
		},
		isCreateOffer() {
			return ['CreateOffer'].includes(this.$route.name)
		},
		unitsCount() {
			return this.$store.state.broker.offer.units.length
		},
		brokerTitle() {
			let title
			if (
				this.$route.name === 'BrokerSearch' ||
				this.$route.name === 'Units' ||
				this.$route.name === 'BrokerProject'
			) {
				title = 'FIND YOUR PROPERTY'
			} else title = 'Agent account '
			return title
		}
	},
	methods: {
		logout() {
			const {
				commit,
				state: { broker }
			} = this.$store

			if (!broker.email) {
				return
			}

			createRequestBroker(requestConfigs.POSTAccountLogout, {
				jsonPayload: {
					login: broker.email
				}
			}).then(() => {
				commit('logout')
				this.$router.go()
			})
		},
		toggleMenu() {
			if (this.activeMenu) {
				this.closeMenu()
			} else {
				this.openMenu()
			}
		},
		openMenu() {
			this.activeMenu = true
			document.body.classList.add('stop-scrolling')
		},
		closeMenu() {
			this.activeMenu = false
			document.body.classList.remove('stop-scrolling')
		}
	}
}
</script>

<style lang="scss">
.broker-header {
	.container {
		overflow: unset;
	}
	&__content {
		display: flex;
		align-items: flex-start;
		padding: 30px 0;
		grid-gap: 10px;
	}
	&__controls {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-left: auto;
		&-btn {
			min-width: 160px;
		}
		&-icon-btn {
			padding: 8px;
			font-size: 16px;
			color: #c99d56;
		}
	}
	&__navigation {
		margin-left: 0;
	}
	&__logo-text {
		font-size: 34px;
		font-weight: 700;
		span {
			color: rgba(201, 157, 86, 1);
			font-size: 14px;
			font-weight: 400;
			display: block;
			word-break: break-word;
		}
	}
	@media (max-width: 1180px) {
		&__controls-btn {
			min-width: 140px;
		}
	}
	@media screen and (max-width: 991px) {
		&__content {
			padding: 20px 0;
		}
		&__logo-text {
			font-size: 18px;
			font-weight: 700;
		}
		&__controls-btn.button {
			min-width: unset;
			font-size: 14px;
			height: auto !important;
			padding: 4px 8px;
			flex-shrink: 0;
		}
		&__navigation.navigation {
			padding: 0;
			& .nav-toggler {
				padding: 0;
			}
			& .navigation__toggler {
				border-left: none;
			}
			& .money-list .money-toggle {
				padding-left: 10px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		&__controls-btn.button,
		&__controls-icon-btn.btn.isBroker {
			display: none;
		}
	}
}
</style>
